import _ from 'lodash'
import { format } from 'date-fns'

export default {
  data () {
    return {
      // for table loading indicator
      isTableLoading: false,
      tableData: [],
      totalCount: 0,

      query: '',
      dateRange: [],
      tableOrderBy: '',
      isDesc: null,
      limit: 10,
      page: 1
    }
  },
  created () {
    this.tableOrderBy = this.defaultTableOrderBy
    this.isDesc = this.defaultTableOrder === 'desc'
    this.throttledUpdateFromQuery = _.throttle(this.loadTableData, 2000, { leading: false })
    this.loadTableData()
  },
  methods: {
    async loadTableData () {
      try {
        this.isTableLoading = true
        const data = await this.fetchTableData(this.generateQueries())
        this.totalCount = data.count
        this.tableData = data.rows
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
        this.tableData = []
        this.total = 0
      } finally {
        this.isTableLoading = false
      }
    },
    generateQueries () {
      const query = []
      if (this.query) {
        query.push(`q=${this.query}`)
      }
      if (this.tableOrderBy) {
        query.push(`orderBy=${this.tableOrderBy}`)
      }
      if (this.isDesc) {
        query.push(`desc=${this.isDesc}`)
      }
      if (this.limit) {
        query.push(`limit=${this.limit}`)
      }
      if (this.page) {
        query.push(`page=${this.page}`)
      }
      if (this.dateRange.length) {
        if (this.dateRange[0]) {
          query.push(`from=${format(this.dateRange[0], 'yyyy-MM-dd')}`)
        }
        if (this.dateRange[1]) {
          query.push(`to=${format(this.dateRange[1], 'yyyy-MM-dd')}`)
        }
      }
      return query.join('&')
    },
    onPageChange (page) {
      this.page = page
      this.loadTableData()
    },
    onSort (field, order) {
      this.tableOrderBy = field
      this.isDesc = order === 'desc'
      this.loadTableData()
    },
    onQueryChange (query) {
      this.query = query
      this.loadTableData()
    },
    clearQuery () {
      if (this.query) {
        this.query = ''
      }
    }
  },
  watch: {
    query: function () {
      this.throttledUpdateFromQuery()
    },
    dateRange: function () {
      this.throttledUpdateFromQuery()
    }
  }
}
